<template>
  <div class="bs-modal bs-modal-overlay modal modal-tag-feed">
    <div class="bs-modal-container">
      <div class="bs-modal-header">
        <div class="bs-modal_cancel">Đóng</div>
        <div class="bs-modal_title">Thẻ bài viết</div>
      </div>
      <div class="dosiin-tag-properties" v-if="post">
        <dl
          class="tag-list-item"
          v-for="(cat_with_name, index) in post.categories_brands_with_name"
          v-bind:key="index"
        >
          <dt class="tag-icon dosiin_mr-2">
            <i :class="['dsi', getCategoryIcon(index)]"></i>
          </dt>
          <dd class="tag-name">
            <div class="dosiin_hashtag_slider swiper-tag-name-detail-feed">
              <div class="swiper-wrapper">
                <div
                  class="swiper-slide"
                  v-for="(brand_name, i) in cat_with_name.brand_name"
                  :key="i"
                >
                  <a href="#" :title="brand_name">
                    <data class="tag-name-item dosiin_mr-1">#{{ brand_name }}</data>
                  </a>
                </div>
              </div>
            </div>
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  setup() {
    const post = ref({});
    const updatePost = (p) => {
      post.value = p;
    };

    return { post, updatePost };
  },
  mounted() {
    const bsModal = $(".bs-modal");
    $(document).on("click", ".dosiin_bs-modal-tag-feed", function () {
      if (!bsModal.hasClass("open")) {
        bsModal.toggleClass("open");
        $("body").toggleClass("no-scroll");
      }
    });
    $("body").on("click", function (e) {
      if (
        bsModal.hasClass("open") &&
        !bsModalContainer.is(e.target) &&
        !bsModalContainer.has(e.target).length
      ) {
        e.stopPropagation();
        bsModal.toggleClass("open");
        $("body").toggleClass("no-scroll");
      }
    });
    $(document).on("click", "#dosiin_bs-modal-cancel-btn", function (e) {
      e.preventDefault();
      if (bsModal.hasClass("open")) {
        bsModal.removeClass("open");
        $("body").removeClass("no-scroll");
      }
    });
  },
};
</script>
