<template>
  <Teleport to="#modals">
    <Modal2
      v-model="show"
      @close="closeModal"
      :modalTypeClass="'modal-medium scroll-box-modal modal-magazine-pc'"
      :overlay="false"
      v-if="postDetail"
    >
      <template v-slot:header>
        <h4 class="title-magazine" v-text="postDetail.title"></h4>
        <div class="cate-time-line">
          <div class="time-tag">Tin tức</div>
          <div class="time-day-box">
            <i class="dsi dsi-clock"></i
            ><span v-text="_momentDayToDateString(postDetail.timestamp)"></span>
          </div>
        </div>
      </template>
      <template v-slot:body>
        <div class="magazine-content" v-html="postDetail.description"></div>
      </template>
      <template v-slot:footer>
        <div class="magazine-comment">
          <div class="magazine-comment-left">
            <CommentFormV2Vue />

            <div class="section-title-wrapper">
              <h2 class="section-title">Thảo luận bài viết</h2>
              <button class="style-button-text">
                <span>Text</span>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M17.5 5.74496C17.5 7.53716 16.077 8.98991 14.3216 8.98991C12.5661 8.98991 11.1431 7.53716 11.1431 5.74496C11.1431 3.95275 12.5661 2.5 14.3216 2.5C16.077 2.5 17.5 3.95275 17.5 5.74496ZM8.25333 2.80814H2.5V8.68187H8.25333V2.80814ZM16.8002 11.641C17.055 11.9012 17.1981 12.254 17.1981 12.6219V15.7212C17.1981 16.089 17.055 16.4419 16.8002 16.702C16.5454 16.962 16.1998 17.1082 15.8394 17.1082H12.8038C12.4435 17.1082 12.0977 16.962 11.8431 16.702C11.5881 16.4419 11.4449 16.089 11.4449 15.7212V12.6219C11.4449 12.254 11.5882 11.9012 11.8431 11.641C12.0977 11.3809 12.4435 11.2348 12.8038 11.2348H15.8394C16.1998 11.2348 16.5454 11.3809 16.8002 11.641ZM8.25351 12.5075L5.43028 10.8433L2.60677 12.5075V15.8359L5.43028 17.5001L8.25351 15.8359V12.5075Z"
                    fill="#A55EE9"
                  ></path>
                </svg>
              </button>
            </div>
            <!-- <div class="list-munti-review brand-review-item">
              <div class="review-item review-item-product">
                <div class="review-item-info">
                  <a class="link-item-product no-short"
                    ><img
                      class="user-avatar"
                      width="40"
                      height="40"
                      src="https://dosi-in.com/file/vnfd/215/dosiin-207615644_169753595058372_3856001861344296331_n215148.jpg?w=670&amp;h=670&amp;fit=crop&amp;fm=webp"
                      alt=""
                  /></a>
                  <div class="rating-info">
                    <h3 class="user-name truncate">Sarah Stanley</h3>
                    <div class="rating-with-time">
                      <span class="rating-time-product rating-time-product-txt"
                        >1 giờ trước</span
                      >
                    </div>
                    <div class="review-item-preview">
                      <div class="review-item-preview_left">
                        <div class="review-item-description">
                          <span
                            >Scelerisque massa turpis est adipiscing. Vitae bibendum proin
                            justo,</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="review-item-action dosiin_mt-12">
                      <div class="review-item-action-btn">
                        <button class="dosiin_like-btn action-btn like-btn"></button>
                        <button
                          class="more-btn dosiin_btn-open-modal dosiin_reply-btn action-btn reply-btn"
                          href="./"
                          title="Xem thêm"
                          value="#dosiin_review"
                        >
                          <i class="dsi dsi-comment"></i>
                        </button>
                      </div>
                      <div class="review-item-action-info">
                        <span class="info-like"><b>12k </b> lượt thích</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
            <template v-if="discussions.posts && discussions.posts.length">
              <div
                class="list-munti-review brand-review-item"
                v-for="(discussion, i) in discussions.posts"
                :key="i"
              >
                <div class="review-item review-item-product">
                  <div class="review-item-info">
                    <router-link
                      :to="{
                        name: 'profile',
                        query: { profile_id: discussion.user_id },
                      }"
                      class="link-item-product no-short"
                    >
                      <img
                        v-lazy="_userAvatar(discussion.user_avatar)"
                        class="user-avatar"
                        width="40"
                        height="40"
                      />
                    </router-link>
                    <div class="rating-info">
                      <h3 class="user-name truncate" v-text="discussion.name"></h3>
                      <div class="rating-with-time"></div>
                      <span
                        class="rating-time-product rating-time-product-txt"
                        v-text="_momentFromNow(discussion.timestamp)"
                      ></span>
                      <div class="review-item-preview"></div>
                      <div class="review-item-preview_left">
                        <div class="review-item-description"></div>
                        <span v-text="discussion.message"></span>
                      </div>
                      <div class="review-item-action dosiin_mt-12">
                        <div class="review-item-action-btn">
                          <LikeButton
                            likeable_type="discussion"
                            :likeable_id="discussion.post_id"
                            :isLiked="discussion.is_like"
                          />

                          <button
                            class="more-btn dosiin_reply-btn comment dosiin_ml-1"
                            @click="
                              $notify({
                                type: 'vv',
                                title: 'Thông báo',
                                text: 'Tính năng đang phát triển.',
                              })
                            "
                          >
                            <i class="dsi dsi-more-h"></i>
                          </button>
                        </div>
                        <div class="review-item-action-info">
                          <span class="info-like"></span
                          ><b>{{ discussion.like_post }} </b> lượt thích
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <div class="no_comments-inner" v-else>
              <div class="no_comments" style="text-align: center">
                <img :src="_emptyReview" />
                <p>Bài viết chưa có thảo luận</p>
              </div>
            </div>
          </div>
          <div class="line-magazine"></div>
          <div class="magazine-comment-right">
            <div class="mazine-author" v-if="postDetail.user_id != 0">
              <h4>TÁC GIẢ</h4>
              <!-- <a class="mazine-author-box" href="">
                <div class="mazine-author-info">
                  <img
                    src="https://dosi-in.com/file/detailed/140/dosiin-xxme-phien-ban-gioi-han-dac-biet-rawr-xmas-tee-pink-140993140993.jpg?w=320&amp;h=320&amp;fit=fill&amp;fm=webp"
                    alt=""
                    width="62"
                    height="62"
                  />
                  <div class="mazine-author-txt">
                    <h3>Cindy Holmes</h3>
                    <p><b>1K2</b>người theo dõi</p>
                  </div>
                </div>
                <i class="dsi dsi-chevron-right"> </i>
              </a> -->
              <router-link
                class="mazine-author-box"
                :to="{ name: 'profile', query: { profile_id: postDetail.user_id } }"
              >
                <div class="mazine-author-info"></div>
                <img
                  width="40"
                  height="40"
                  v-lazy="{
                    src: _userAvatar(postDetail.avatar),
                    loading: _userDefaultAvatar,
                    error: _userDefaultAvatar,
                  }"
                />
                <div class="mazine-author-txt">
                  <h3 v-text="postDetail.user_name"></h3>
                  <p class="dosiin_mt-1"></p>
                  <b v-text="postDetail.follow_count"></b>&nbsp;người theo dõi
                </div>
                <i class="dsi dsi-chevron-right"> </i>
              </router-link>
              <FollowButton
                class="brand_follow-btn primary-button button"
                followable_type="user"
                :followable_id="postDetail.author?.user_id"
                :is_follow="postDetail.author?.is_follow"
              />
            </div>
            <!---->
            <div class="mazine-author">
              <h4>CHI TIẾT BÀI VIẾT</h4>
              <div class="mazine-total">
                <div class="mazine-total-item">
                  <h5>{{ postDetail.popularity }}</h5>
                  <p>Lượt xem</p>
                </div>
                <div class="mazine-total-item">
                  <h5>{{ postDetail.like_count }}</h5>
                  <p>Lượt thích</p>
                </div>
                <div class="mazine-total-item">
                  <h5>{{ postDetail.discussion_count }}</h5>
                  <p>Thảo luận</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Modal2>
  </Teleport>
</template>

<script>
import Modal2 from "@/components/modals/ModalMag";
import CommentFormV2Vue from "@/components/forms/CommentFormV2.vue";
import StyleService from "@/services/style";
import DiscussionService from "@/services/discussion";
import UserService from "@/services/user";

import { Navigation, Lazy } from "swiper";
import { ref, reactive, onMounted, watch, computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

export default {
  components: {
    CommentFormV2Vue,
    Modal2,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    onMounted(() => {
      store.dispatch("modal/initModal");
    });

    const swiperOptionsDetail = {
      slideToClickedSlide: true,
      watchSlidesVisibility: false,
      watchSlidesProgress: false,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    };

    const state = reactive({
      loading: false,
    });

    const getDiscussionStore = store.getters["PostsStore/getDiscussion"];
    let discussions = computed(() => getDiscussionStore(postDetail.value.vnfd_id));
    const loading = ref(true);
    const postDetail = ref({});
    const originUrl = window.location.origin;
    const show = ref(false);

    function detailPost(post) {
      state.loading = true;
      postDetail.value = post;
      show.value = true;
      store.dispatch("modal/increase");

      getPostDetail(postDetail.value.vnfd_id);

      getAuthor(postDetail.value.user_id);
      setTimeout(() => {
        state.loading = false;
      }, 300);
    }

    const closeModal = () => {
      show.value = false;
      store.dispatch("modal/decrease");
    };

    // methods
    async function getPostDetail(vnfd_id) {
      const response = await StyleService.fetchPost(vnfd_id, {
        timestamp: new Date().getTime(),
      });

      let paramsDiscussion = {
        vnfd_id: vnfd_id,
        params: {
          object_id: vnfd_id,
          type: "V",
          timestamp: new Date().getTime(),
        },
      };

      getDiscussion(paramsDiscussion);
      store.dispatch("PostsStore/addPosts", { postData: [response.data] });
      return response.data;
    }

    async function getDiscussion({ params, vnfd_id }) {
      const response = await DiscussionService.fetchPostDiscussion(vnfd_id, params);
      store.commit("PostsStore/PUSH_DATA_DISCUSSIONS", { data: response.data, vnfd_id });
    }

    watch(
      () => route.name,
      () => {
        closeModal();
      }
    );

    async function getAuthor(user_id) {
      const response = await UserService.fetchUser(user_id);
      if (response && response.data) {
        postDetail.value.author = response.data;
      }
    }

    return {
      state,
      show,
      detailPost,
      loading,
      originUrl,
      postDetail,
      discussions,
      swiperOptionsDetail,
      modules: [Navigation, Lazy],
      closeModal,
    };
  },
  methods: {
    sortDiscussion(event, vnfd_id) {
      let button = event.target;
      let sort_order = "desc";

      if (button.classList.contains("dsi-drop-down")) {
        sort_order = "desc";
        button.classList.remove("dsi-drop-down");
        button.classList.add("dsi-drop-up");
      } else {
        sort_order = "asc";
        button.classList.remove("dsi-drop-up");
        button.classList.add("dsi-drop-down");
      }

      this.CHANGE_DISCUSSION_SORT({
        sort_by: "timestamp",
        sort_order: sort_order,
        vnfd_id: this.vnfd_id,
      });
    },
    sharePostDropdown(post, event) {
      const modal_post = true;
      this.$refs.dropdownShare.updatePost(post, event, modal_post);
    },
  },
};
</script>

<style>
.desc img {
  width: 100% !important;
  height: auto;
}

.news-title {
  font-weight: 700;
  font-size: 1.6em;
  line-height: 1.2em;
  margin: 0;
  padding-top: 30px;
  margin-bottom: 1.2rem;
}

#firstContent {
  clear: both;
  display: block;
  visibility: visible;
  opacity: 1;
  position: relative;
  line-height: 30px;
}

.dosiin_post-detail {
  left: 0;
}

.dosiin_post-detail .swiper,
.dosiin_post-detail .dosiin_post-img-slider .swiper-wrapper {
  z-index: 0 !important;
  height: 100%;
}

.scroll-bar-wrap .scroll-box {
  padding-bottom: 90px;
}
.magazine-comment-left .no-login {
  background: #ebebeb;
  border: 1px solid rgba(16, 16, 16, 0.1);
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 16px;
}
</style>
