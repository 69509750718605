<template>
  <section class="suggestions-famous" :class="layout != 4 ? '' : 'overflow'">
    <div class="header-section">
      <h2 class="header-section-left-side section-title">
        {{ $t("style.suggest_user") }}
      </h2>
    </div>
    <div class="dosiin_position-relative">
      <Swiper
        :modules="modules"
        :slideToClickedSlide="swiperOptions.slideToClickedSlide"
        :watchSlidesVisibility="swiperOptions.watchSlidesVisibility"
        :watchSlidesProgress="swiperOptions.watchSlidesProgress"
        :breakpoints="swiperOptions.breakpoints"
        class="dosiin_suggestions_slider suggestions_slider"
        :class="layout != 4 ? 'dosiin_position-unset' : 'overflow-unset'"
      >
        <SwiperSlide class="swiper-slide" v-for="(user, i) in users" :key="i">
          <router-link
            class="img"
            :to="{ name: 'profile', query: { profile_id: user.user_id } }"
          >
            <img
              width="110"
              height="109"
              v-lazy="{ src: _userAvatar(user.avatar), error: _userRandomAvatar() }"
            />
          </router-link>

          <div class="desc">
            <router-link
              class="img"
              :to="{ name: 'profile', query: { profile_id: user.user_id } }"
            >
              <div class="name truncate" v-if="user.firstname.length > 0">
                {{ user.firstname }}
              </div>
              <div class="name" v-else>-</div>
              <div class="follow-people">
                <b>{{ user.follow_count }} </b>&nbsp;người theo dõi
              </div>
            </router-link>

            <hr class="line" />

            <FollowButton
              v-model="user.follow_count"
              @update:modelValue="(newValue) => (user.follow_count = newValue)"
              followable_type="user"
              :followable_id="user.user_id"
              :is_follow="user.is_follow"
            />
          </div>
        </SwiperSlide>
        <div
          v-if="layout != 4"
          class="swiper-button-prev dosiin_swiper-button dosiin_position-button-prev"
        ></div>
        <div
          v-if="layout != 4"
          class="swiper-button-next dosiin_swiper-button dosiin_position-button-next"
        ></div>
      </Swiper>
    </div>
  </section>
</template>

<script>
import UserService from "@/services/user";
import { Navigation } from "swiper";

export default {
  setup() {
    const swiperOptions = {
      slideToClickedSlide: false,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      breakpoints: {
        320: {
          slidesPerView: 2.2,
          spaceBetween: 9,
        },
        769: {
          slidesPerView: 5,
          spaceBetween: 9,
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
        },
      },
    };

    return {
      swiperOptions,
      modules: [Navigation],
    };
  },
  data() {
    return {
      users: [],
    };
  },
  created() {
    this.getUsers();
  },
  methods: {
    async getUsers() {
      var params = {
        items_per_page: "20",
        user_type: "V",
        status: "A",
        sort_by: "random",
        get_vnfd: true,
      };

      const response = await UserService.fetchUsers(this.serialize(params));

      if (response.data) {
        this.users.push(...response.data.users);
      }
    },
  },
};
</script>
