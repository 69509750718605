<template>
  <div>
    <div class="header-section" v-if="layout != 4">
      <h2
        class="header-section-left-side section-title"
        v-text="$t('style.top_post')"
      ></h2>
      <button
        class="dosiin_comment-sort comment-sort-btn d-flex align-items-center justify-content-between"
        @click="orderSort"
      >
        <span v-text="pagination.sort_order == 'desc' ? 'Mới nhất' : 'Cũ nhất'"></span>
        <i
          :class="[
            'dsi dosiin_ml-2',
            pagination.sort_order == 'desc' ? 'dsi-drop-down' : 'dsi-drop-up',
          ]"
        ></i>
      </button>
    </div>
    <section class="dosiin_wrapper-vnfd" id="dosiin_wrapper-vnfd">
      <PostSkeleton v-if="state.loading"></PostSkeleton>
      <EmptyComponent
        v-else-if="pagination.page == 1 && state.endLoad"
        :title="true"
        :des="false"
      />
      <!-- layout 1 -->
      <InfiniteLoad
        v-else
        @infinite-scroll="checkIsLoadingNextPage"
        :isLoading="state.lazyLoad"
        :noResult="state.endLoad"
      >
        <div
          v-if="style == 'special'"
          class="dosiin_wrapper-vnfd-item card accordion"
          v-for="(post, key, i) in postLoader"
          :key="post.vnfd_id"
        >
          <UserSuggest v-if="i == 5" v-bind:key="i + 'sugges'"></UserSuggest>

          <GroupTags v-else-if="i == 8 && layout == 4"></GroupTags>

          <div class="card-body accordion-posts">
            <div class="post-group overflow">
              <div class="dosiin_post-img-slider post-img-slider">
                <Attach :post="post" @showTags="tagPost(post)" />

                <Swiper
                  :modules="modules"
                  :pagination="ImagePostSlide.pagination"
                  :slidesPerView="ImagePostSlide.slidesPerView"
                  :observer="ImagePostSlide.observer"
                  :observeParents="ImagePostSlide.observeParents"
                  :observeSlideChildren="ImagePostSlide.observeSlideChildren"
                  :slideToClickedSlide="ImagePostSlide.slideToClickedSlide"
                  :watchSlidesVisibility="ImagePostSlide.watchSlidesVisibility"
                  :watchSlidesProgress="ImagePostSlide.watchSlidesProgress"
                  :breakpoints="ImagePostSlide.breakpoints"
                  :class="layout != 4 ? 'dosiin_position-unset' : ''"
                  @click="layout != 4 ? showDetailPost(post) : ''"
                >
                  <Swiper-slide>
                    <div class="img-post-wrapper">
                      <router-link :to="_vnfdUrl(post.seo_name)" v-if="layout == 4">
                        <img
                          v-lazy="{
                            src: _postImage(post.main_pair),
                            loading: _styleDefault,
                            error: _styleDefault,
                          }"
                          class="dosiin_w-100"
                          width="670"
                          height="670"
                        />
                      </router-link>
                      <img
                        v-else
                        v-lazy="{
                          src: _postImage(post.main_pair),
                          loading: _styleDefault,
                          error: _styleDefault,
                        }"
                        class="dosiin_w-100"
                        width="670"
                        height="670"
                      />
                    </div>
                    <div class="ds_position-top-left" v-if="post.type == 'm'">
                      <i class="dsi dsi-s2036 dsi-tag-magazine"></i>
                    </div>
                  </Swiper-slide>
                  <Swiper-slide v-for="(img, i) in post.addition_images" :key="i">
                    <div class="img-post-wrapper">
                      <router-link :to="_vnfdUrl(post.seo_name)" v-if="layout == 4">
                        <img
                          v-lazy="{
                            src: _postImageWithFullUrl(img),
                            loading: _styleDefault,
                            error: _styleDefault,
                          }"
                          class="dosiin_w-100"
                          width="670"
                          height="670"
                        />
                      </router-link>
                      <img
                        v-else
                        v-lazy="{
                          src: _postImageWithFullUrl(img),
                          loading: _styleDefault,
                          error: _styleDefault,
                        }"
                        class="dosiin_w-100"
                        width="670"
                        height="670"
                      />
                    </div>
                    <div class="ds_position-top-left" v-if="post.type == 'm'">
                      <i class="dsi dsi-s2036 dsi-tag-magazine"></i>
                    </div>
                  </Swiper-slide>
                  <div class="swiper-pagination"></div>
                  <div
                    v-if="layout != 4"
                    class="swiper-button-prev dosiin_swiper-button-gradient dosiin_position-button-prev"
                  ></div>
                  <div
                    v-if="layout != 4"
                    class="swiper-button-next dosiin_swiper-button-gradient dosiin_position-button-next"
                  ></div>
                </Swiper>
              </div>
              <div class="post-group-info post-group-mr">
                <div class="mag-header d-flex align-items-center" :class="layout == 4 ? 'dosiin_mb-2' : ''" v-if="post.type == 'm'">
                  <div class="tag-post dosiin_mr-2">Tin tức</div>
                  <div class="time d-flex">
                    <i class="dsi dsi-s16 dsi-clock dosiin_mr-1"></i
                    ><span v-text="_momentDayToDateString(post.timestamp)"></span>
                  </div>
                </div>
                <PostHeader :post="post" :show_back_button="false" />
                <Tags :post="post" />
                <div class="desc">{{ post.short_description }}</div>
                <div class="dosiin_product-list product-list">
                  <Swiper
                    :observer="true"
                    :observeParents="true"
                    :observeSlideChildren="true"
                    :spaceBetween="9"
                    :slidesPerView="1.2"
                    class="overflow-unset"
                  >
                    <Swiper-slide
                      v-for="(product, p_id) in post.products"
                      v-bind:key="p_id"
                      :class="
                        Object.keys(post.products).length == 1 ? 'fit-box-product' : ''
                      "
                    >
                      <div class="list-item product-list-item product-list-item-style">
                        <router-link
                          class="list-item_img"
                          :to="_productUrl(product)"
                          :title="product.product"
                        >
                          <img
                            class="product-sm-item_img"
                            v-lazy="_productThumbnail(product.main_pair)"
                            :alt="product.product"
                            :title="product.product"
                            width="76"
                            height="76"
                          />
                        </router-link>
                        <dl class="list-item_summary">
                          <div class="product-list-item_name_wrapper">
                            <dd class="list-item_name">
                              <router-link
                                class="list-item_product"
                                :to="_productUrl(product)"
                                :title="product.product"
                              >
                                {{ product.product }}</router-link
                              >
                            </dd>
                          </div>
                          <div class="list-item_sale product-list-item_price_wrapper">
                            <dd
                              class="list-item_price"
                              v-text="currencyFormat(product.price)"
                            ></dd>
                            <dd
                              class="inline-bl list-item_sale_origin"
                              v-if="product.list_price > product.price"
                            >
                              {{ currencyFormat(product.list_price) }}
                            </dd>
                            <dd
                              class="inline-bl list-item_sale_discount"
                              v-if="discountPrc(product) > 0"
                            >
                              {{ discountPrc(product) }}%
                            </dd>
                          </div>
                        </dl>
                      </div>
                    </Swiper-slide>
                  </Swiper>
                </div>
                <div class="action-group">
                  <div class="like-desc">
                    <span>
                      <b>{{ post.like_count }} </b>&nbsp;lượt thích
                    </span>
                    <span class="dosiin_ml-1 dosiin_mr-1"> • </span>
                    <span>
                      <b>{{ post.discussion_count }} </b>&nbsp;bình luận
                    </span>
                  </div>
                  <div class="share-desc">
                    <b>{{ post.share_count }} </b>&nbsp;lượt chia sẻ
                  </div>
                </div>
                <hr class="line" />
                <div class="link-action">
                  <LikeButton
                    likeable_type="vnfd"
                    :likeable_id="post.vnfd_id"
                    :isLiked="post.is_like"
                    :indexPost="key"
                    :modal_detail="true"
                    :object="post"
                    type="text"
                  />

                  <CommentButton
                    isType="link"
                    :vnfd_id="post.vnfd_id"
                    :url="post.seo_name"
                    v-if="layout == 4"
                  />
                  <button
                    v-else
                    class="more-btn dosiin_reply-btn comment"
                    title="Viết đánh giá"
                    @click="showDetailPost(post)"
                  >
                    <i class="dsi dsi-comment"></i> Bình luận
                  </button>

                  <a v-if="layout == 4" class="share_dropdow" @click="sharePost(post)">
                    <i class="dsi dsi-share dsi dsi-share dosiin_mr-0"></i>
                  </a>

                  <button
                    v-else
                    class="share_dropdow"
                    @click="sharePostDropdown(post, $event)"
                  >
                    <i class="dsi dsi-share"></i>
                    <span>Chia sẻ</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- layout 2 -->
        <div v-else class="topic-detail-list dosiin_post_view">
          <div class="dosiin-categories-item">
            <div
              class="item-categories"
              v-for="post in postLoader"
              :key="post.vnfd_id"
              v-bind:style="{
                background:
                  'url(' + _postImage(post.main_pair) + ') no-repeat center center',
                backgroundSize: 'cover',
              }"
            >
              <div class="blur-categories-item">
                <div class="content-categories" style="position: relative; z-index: 2">
                  <div class="content-categories-img">
                    <router-link :to="_vnfdUrl(post.seo_name)">
                      <img
                        v-lazy="{
                          src: _postImage(post.main_pair),
                          loading: _styleDefault,
                          error: _styleDefault,
                        }"
                      />
                    </router-link>
                  </div>
                  <div class="dosiin-tag-feed">
                    <div class="item-categories-icons">
                      <a
                        class="tag-feed-btn dosiin_bs-modal-tag-feed d-flex align-items-center justify-content-between dosiin_mr-2"
                      >
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M9.18832 2.78866C9.47476 2.49609 9.86421 2.33203 10.2692 2.33203H13.4159C14.1014 2.33203 14.6662 2.90816 14.6666 3.60728V6.81755C14.6666 7.23076 14.5054 7.6274 14.219 7.91956L9.5866 12.6447C8.99336 13.2499 8.01858 13.2499 7.42533 12.6447L7.3944 12.6132L6.80837 13.2115C6.21512 13.8166 5.24035 13.8166 4.6471 13.2115L1.77825 10.2846C1.185 9.67951 1.185 8.68575 1.77825 8.08062L6.41008 3.35544C6.69653 3.06287 7.08598 2.89881 7.49099 2.89881H9.08034L9.18832 2.78866ZM8.24687 3.74898H7.49099C7.30646 3.74898 7.13021 3.82346 6.99992 3.95654L2.36754 8.68172C2.09286 8.9619 2.09286 9.40337 2.36754 9.68354L5.23693 12.6099C5.51161 12.89 5.94441 12.89 6.21908 12.6099L6.80511 12.0121L4.55648 9.71786C3.96323 9.11273 3.96323 8.11898 4.55648 7.51385L8.24687 3.74898ZM12.1657 4.03237C11.9446 4.03237 11.7326 4.12194 11.5763 4.28138C11.42 4.44081 11.3322 4.65706 11.3322 4.88253C11.3322 5.10801 11.42 5.32426 11.5763 5.48369C11.7326 5.64313 11.9446 5.7327 12.1657 5.7327C12.3867 5.7327 12.5987 5.64313 12.755 5.48369C12.9113 5.32426 12.9992 5.10801 12.9992 4.88253C12.9992 4.65706 12.9113 4.44081 12.755 4.28138C12.5987 4.12194 12.3867 4.03237 12.1657 4.03237Z"
                            fill="#E6E6E6"
                          ></path>
                        </svg>
                        <span
                          class="dosiin_ml-1"
                          v-text="Object.keys(post.categories_brands_with_name).length"
                        ></span>
                      </a>
                    </div>
                    <div class="item-categories-icons">
                      <a
                        class="tag-feed-btn dosiin_bs-modal-tag-feed d-flex align-items-center justify-content-between"
                      >
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M7.43718 1.96278H10.6486C11.0313 1.96278 11.3987 2.11362 11.67 2.38281C11.9414 2.65207 12.0942 3.01773 12.0942 3.39949V5.90496H12.7343V3.39949C12.7343 2.85188 12.5151 2.32625 12.1242 1.93835C11.7332 1.55038 11.2024 1.33203 10.6486 1.33203H3.58574C3.03186 1.33203 2.5011 1.55038 2.11011 1.93835C1.7192 2.32625 1.5 2.85188 1.5 3.39949V10.4078C1.5 10.9554 1.71921 11.4811 2.11011 11.8689C2.5011 12.2569 3.03186 12.4753 3.58574 12.4753H5.00934V11.8445H3.58574C3.20304 11.8445 2.83557 11.6937 2.56428 11.4245C2.29292 11.1552 2.14007 10.7895 2.14007 10.4078V3.39949C2.14007 3.01773 2.29292 2.65207 2.56428 2.38281C2.83556 2.11362 3.20302 1.96278 3.58574 1.96278H6.79711V4.37189H7.43718V1.96278ZM13.2971 7.90233H8.44143C8.1217 7.90233 7.81549 8.0284 7.59007 8.25208C7.36473 8.47568 7.23854 8.77847 7.23854 9.09375V13.4739C7.23854 13.7892 7.36473 14.092 7.59007 14.3156C7.81549 14.5393 8.1217 14.6654 8.44143 14.6654H13.2971C13.6168 14.6654 13.923 14.5393 14.1485 14.3156C14.3738 14.092 14.5 13.7892 14.5 13.4739V9.09375C14.5 8.77847 14.3738 8.47568 14.1485 8.25208C13.923 8.02839 13.6168 7.90233 13.2971 7.90233ZM11.1401 10.5042V8.53308H10.5V10.5042H11.1401Z"
                            fill="#E6E6E6"
                          ></path>
                        </svg>
                        <span
                          class="dosiin_ml-1"
                          v-text="Object.keys(post.products).length"
                        ></span>
                      </a>
                    </div>
                    <div class="item-categories-icons">
                      <a
                        class="tag-feed-btn dosiin_bs-modal-tag-feed d-flex align-items-center justify-content-between"
                      >
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.74998 2.33336C3.6874 2.33248 3.62528 2.34404 3.56721 2.36737C3.50914 2.3907 3.45629 2.42535 3.41173 2.46928C3.36716 2.51322 3.33178 2.56558 3.30762 2.62331C3.28347 2.68104 3.27103 2.743 3.27103 2.80558C3.27103 2.86816 3.28347 2.93012 3.30762 2.98785C3.33178 3.04558 3.36716 3.09794 3.41173 3.14188C3.45629 3.18581 3.50914 3.22046 3.56721 3.24379C3.62528 3.26713 3.6874 3.27869 3.74998 3.2778H12.25C12.3126 3.27869 12.3747 3.26713 12.4328 3.24379C12.4908 3.22046 12.5437 3.18581 12.5882 3.14188C12.6328 3.09794 12.6682 3.04558 12.6923 2.98785C12.7165 2.93012 12.7289 2.86816 12.7289 2.80558C12.7289 2.743 12.7165 2.68104 12.6923 2.62331C12.6682 2.56558 12.6328 2.51322 12.5882 2.46928C12.5437 2.42535 12.4908 2.3907 12.4328 2.36737C12.3747 2.34404 12.3126 2.33248 12.25 2.33336H3.74998ZM3.27776 4.22224C3.21518 4.22136 3.15306 4.23292 3.09499 4.25625C3.03692 4.27959 2.98407 4.31423 2.9395 4.35817C2.89494 4.4021 2.85955 4.45446 2.8354 4.51219C2.81125 4.56992 2.79881 4.63188 2.79881 4.69446C2.79881 4.75704 2.81125 4.819 2.8354 4.87673C2.85955 4.93446 2.89494 4.98682 2.9395 5.03076C2.98407 5.0747 3.03692 5.10934 3.09499 5.13267C3.15306 5.15601 3.21518 5.16757 3.27776 5.16668H12.7222C12.7848 5.16757 12.8469 5.15601 12.905 5.13267C12.963 5.10934 13.0159 5.0747 13.0605 5.03076C13.105 4.98682 13.1404 4.93446 13.1646 4.87673C13.1887 4.819 13.2011 4.75704 13.2011 4.69446C13.2011 4.63188 13.1887 4.56992 13.1646 4.51219C13.1404 4.45446 13.105 4.4021 13.0605 4.35817C13.0159 4.31423 12.963 4.27959 12.905 4.25625C12.8469 4.23292 12.7848 4.22136 12.7222 4.22224H3.27776ZM3.27776 6.11112C2.75595 6.11112 2.33331 6.53376 2.33331 7.05556V12.7222C2.33331 13.244 2.75595 13.6666 3.27776 13.6666H12.7222C13.244 13.6666 13.6666 13.244 13.6666 12.7222V7.05556C13.6666 6.53376 13.244 6.11112 12.7222 6.11112H3.27776Z"
                            fill="#E6E6E6"
                          ></path>
                        </svg>
                        <span
                          class="dosiin_ml-1"
                          v-text="
                            post.addition_images.length > 0
                              ? post.addition_images.length
                              : 1
                          "
                        ></span>
                      </a>
                    </div>
                  </div>
                  <div class="item-categories-info-user">
                    <div class="avatar-info">
                      <img v-lazy="_userAvatar(post.avatar)" />
                    </div>
                    <div class="name-info truncate">
                      <span v-text="post.user_name"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </InfiniteLoad>

      <div
        class="d-flex dosiin_w-100"
        style="height: 80px; align-items: center; justify-content: center"
        v-if="state.endLoad"
      >
        Đã load hết bài viết
      </div>
    </section>

    <BrandsCategriesModal ref="modalTag" />
    <ShareButtonModal v-if="layout == 4" ref="modalShare" />
    <ShareButtonDropdown v-else ref="dropdownShare" />
    <PostDetailModal
      v-if="layout !== 4"
      ref="modalPostDetail"
      v-model="showDetailModal"
    />
    <DetailPostMagVue 
      v-if="layout !== 4"
      ref="modalPostDetailMagazine"
      v-model="showDetailModalMagazine"
    /> 
  </div>
</template>

<script>
import { ref, reactive, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { Navigation, Pagination } from "swiper";
import { _addLeadingZero } from "@/utlis/helpers";

import PostHeader from "@/components/post/Header.vue";
import PostSkeleton from "@/components/skeletons/PostLoader";
import StyleService from "@/services/style";
import BrandsCategriesModal from "@/components/modals/BrandCategoriesModal";
import Attach from "@/components/post/Attach";
import Tags from "@/components/post/Tags";
import InfiniteLoad from "@/components/common/InfiniteScroll";
import GroupTags from "@/components/hashtag/GroupTags.vue";
import PostDetailModal from "@/components/modals/DetailPostStyle";
import UserSuggest from "@/components/UserSuggest";
import DetailPostMagVue from "../modals/DetailPostMag.vue";
export default {
  components: {
    InfiniteLoad,
    PostHeader,
    PostSkeleton,
    UserSuggest,
    BrandsCategriesModal,
    Attach,
    Tags,
    GroupTags,
    PostDetailModal,
    DetailPostMagVue
  },
  props: {
    params: {
      type: Object,
      default: {},
    },
    style: {
      type: String,
      default: "special",
    },
  },
  emits: ["updateTotals", "totalPost"],
  setup(props, { emit }) {
    const ImagePostSlide = {
      slidesPerView: "auto",
      observer: true,
      observeParents: true,
      observeSlideChildren: true,
      slideToClickedSlide: true,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      pagination: {
        el: ".swiper-pagination",
        type: "fraction",
        formatFractionCurrent: (number) => _addLeadingZero(number),
        formatFractionTotal: (number) => _addLeadingZero(number),
      },
      breakpoints: {
        769: {
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
        },
      },
    };

    const ProductSlide = {
      observer: true,
      observeParents: true,
      observeSlideChildren: true,
      spaceBetween: 9,
      slidesPerView: "1.2",
    };

    const sort_by = ref("timetamps");
    const sort_order = ref("desc");
    const showDetailModal = ref(false);
    const showDetailModalMagazine = ref(false);
    const state = reactive({
      lazyLoad: false,
      endLoad: false,
      loading: true,
    });

    const postShare = reactive({});
    const pagination = ref({});
    const store = useStore();
    const postLoader = computed(() => store.state.PostsStore.postLoader);
    // refs
    const modalShare = ref(null);
    const dropdownShare = ref(null);
    const modalTag = ref(null);
    const modalPostDetail = ref(null);
    const modalPostDetailMagazine = ref(null);
    const bsModalDetailPost = document.querySelector("#dosiin_post-detail");

    //reset post loader
    store.commit("PostsStore/DELETE_POST_LOADER");
    pagination.value = props.params;

    onMounted(() => {
      getPosts();
    });

    const checkIsLoadingNextPage = () => {
      if (!state.lazyLoad && !state.endLoad) {
        getPosts();
      }
    };

    async function getPosts() {
      state.lazyLoad = true; // deny multi request

      const response = await StyleService.fetchPosts(pagination.value);
      const postData = response.data.vnfd;

      // end of post data
      if (postData.length == 0) {
        state.endLoad = true;
        state.loading = false;
        state.lazyLoad = false;
        return;
      }

      pagination.value = response.data.params;
      pagination.value.page = pagination.value.page + 1;
      store.dispatch("PostsStore/addPosts", { postData });
      emit("totalPost", pagination.value.total_items);

      // load status false => ready to load next reqeust , true => is loading
      state.lazyLoad = false;
      // load skeletons when init page load
      state.loading = false;
    }

    function sharePost(post) {
      modalShare.value.updatePost(post);
    }

    function sharePostDropdown(post, event) {
      dropdownShare.value.updatePost(post, event);
    }

    function tagPost(post) {
      modalTag.value.updatePost(post);
    }

    function showDetailPost(post) {
      if(post && post.type == 's'){
        modalPostDetail.value.detailPost(post);
      }else if(post.type == 'm'){
        modalPostDetailMagazine.value.detailPost(post);
      }
    }

    function reset() {
      store.commit("PostsStore/DELETE_POST_LOADER");
      getPosts();
    }

    function orderSort() {
      state.loading = true;

      if (pagination.value.sort_by == "random") {
        pagination.value.sort_by = "timestamp";
      }

      if (pagination.value.sort_by == "timestamp") {
        if (pagination.value.sort_order == "desc") {
          pagination.value.sort_order = "asc";
        } else {
          pagination.value.sort_order = "desc";
        }
      }

      pagination.value.page = 1;
      store.commit("PostsStore/DELETE_POST_LOADER");
      getPosts();
    }

    return {
      state,
      sort_by,
      sort_order,
      postShare,
      ImagePostSlide,
      ProductSlide,
      modules: [Navigation, Pagination],
      // methods
      tagPost,
      orderSort,
      getPosts,
      reset,
      sharePostDropdown,
      sharePost,
      postLoader,
      checkIsLoadingNextPage,
      //refs
      modalShare,
      dropdownShare,
      modalTag,
      pagination,
      modalPostDetail,
      showDetailPost,
      showDetailModal,
      showDetailModalMagazine,
      modalPostDetailMagazine,
    };
  },
};
</script>
<style>
.dosiin_product-list .fit-box-product {
  width: 100% !important;
}
</style>
